<template>
  <div>
    <navBar title="缴纳保证金" ></navBar>

    <div class="cash">
      <div class="first">
        <div class="first-1">1</div>
        <div class="first-title">缴纳保证金</div>
        <div class="first-img">
          <img src="../assets/earnings/icon_right.png" alt="" />
        </div>
        <div
          class="first-1"
          :style="{ opacity: depositIndex == 1 ? '1' : '0.3' }"
        >
          2
        </div>
        <div
          class="first-title"
          :style="{ opacity: depositIndex == 1 ? '1' : '0.3' }"
        >
          提交审核
        </div>
      </div>

      <div class="second">
        <div class="second-title" style="margin-bottom: 10px">
          {{ sourceIndex == 0 ? "保证金额" : "退还保证金额" }}
        </div>

        <div
          class="second-input"
          :style="{ 'margin-bottom': sourceIndex == 1 ? '5px' : '' }"
        >
          <el-input
            :disabled="sourceIndex == 2 ? true : false"
            v-model="depositPrice"
            :placeholder="returnTitle"
            @input="depositPrice = depositPrice.replace(/^(0+)|[^\d]+/g, '')"
          ></el-input>
        </div>

        <div class="second-error" v-if="sourceIndex == 1">
          最多可提取 ¥ {{ returnPrice }}
        </div>

        <div class="second-title">选择通道</div>

        <div class="second-aisle">
          <div
            class="aisle-list"
            v-for="(item, index) in 1"
            :key="index"
            :class="{ 'aisle-list-active': index == aisleActive }"
            @click="aisleStop(index)"
          >
            <img
              style="width: 21px; height: 21px"
              src="../assets/personage/icon-3.png"
              alt=""
            />

            <div class="list-title">支付宝</div>
          </div>

          <!-- <div
              class="aisle-list"
              v-for="(item, index) in 4"
              :key="index"
              :class="{ 'aisle-list-active': index == aisleActive }"
              @click="aisleStop(index)"
            >
              <img
                :style="{
                  width: index == 0 ? '25px' : index == 1 ? '21px' : '26px',
                  height: index == 0 ? '25px' : index == 1 ? '21px' : '16px',
                }"
                :src="
                  index == 0
                    ? require('../assets/personage/icon-2.png')
                    : index == 1
                    ? require('../assets/personage/icon-3.png')
                    : require('../assets/personage/icon-4.png')
                "
                alt=""
              />
  
              <div class="list-title">
                {{
                  index == 0
                    ? "微信支付"
                    : index == 1
                    ? "支付宝"
                    : index == 2
                    ? "银行卡"
                    : "银联在线"
                }}
              </div>
            </div> -->
        </div>
      </div>

      <div class="button">
        <div class="button-top" @click="paymentClick">
          {{ sourceIndex == 0 ? "确认支付" : "确认退还" }}
        </div>
        <div class="button-text" @click="depositCheckedMet">
          <div class="text-checkbox" v-if="depositChecked == false"></div>
          <img
            class="text-checkboxs"
            v-else
            src="../assets/personage/type-1.png"
            alt=""
          />

          <div class="text-checka">
            我已阅读并同意
            <a
              style="color: #29d647"
              href="https://front.xmw1688.com/baozhengjin.html"
              target="_blank"
              >《{{ sourceIndex == 0 ? "缴纳保证金协议" : "支付协议" }}》</a
            >
          </div>
        </div>
      </div>
    </div>

    <!--弹出框-->
    <van-popup v-model="listShow" round>
      <div class="hint">
        <div class="hint-header">
          <div class="header">提示</div>
          <img
            class="close"
            @click="closeMet"
            src="../assets/login/close.png"
            alt=""
          />
        </div>

        <div class="hint-center">
          <div class="center">
            {{ listTitle }}
          </div>

          <div class="display">
            <div
              class="display-list"
              v-for="(item, index) in 2"
              :key="index"
              @click="hintStop(index)"
            >
              {{ index == 0 ? "取消" : "确认" }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    
    <van-dialog
      v-model="supporyShow"
      title=" "
      show-cancel-button
      @confirm="openNewWindow"
    >
    <p style="text-align: center;">在线支付中</p>
    </van-dialog>
  </div>
</template>
  
  <script>
import {
  postCollaborateot_pay
} from "../api/pay";

import navBar from "../components/navbar.vue";

export default {
  components: {
    navBar,
  },
  data() {
    return {
      sourceIndex: 0,
      depositIndex: 0,
      //-----------------
      depositPrice: "",
      aisleActive: -1,
      depositObj: {},
      depositChecked: false,
      // 聚合生成订单号
      aggregatepaymentOrder: "",
      //-------退款
      returnTitle: "请输入缴纳金额",
      returnPrice: "",
      //--------
      listShow: false,
      listTitle:
        "保证金存缴时间少于3个月，退款保证金时将扣除5%的手续费，是否同意？",
      personageObj: {},

      supporyShow: false,
      supporyShowTitle: ''
    };
  },
  created() {
    // this.sourceIndex = this.$route.query.index;
    // // 获取钱包信息
    // this.getWalletInfo();
    // this.personageMet();
    // clearInterval(this.timers);
  },
  methods: {
    personageMet() {
      this.personageObj = JSON.parse(localStorage.getItem("userInfo"));
    },

    getWalletInfo() {
      let params = {};

      getWalletInfo(params).then((res) => {
        if (res.code == 200) {
          this.depositObj = res.content;
          let num =
            Number(this.depositObj.depositMoney) -
            Number(this.depositObj.collabrate_recharge);

          if (this.sourceIndex == 0) {
            this.returnTitle = `最低缴纳保证${this.depositObj.collabrate_recharge}元`;
          } else {
            this.returnTitle = "最大可提现保证金：" + num;
            this.returnPrice = num;

            if (this.sourceIndex == 2) {
              this.depositPrice = this.depositObj.depositMoney;
            }
          }
        } else {
          // // 退出登陆 清除用户资料
          localStorage.clear();
          // 重设路由
          this.$router.replace({
            path: "/login",
          });
        }
      });
    },

    //----------
    aisleStop(index) {
      if (this.sourceIndex != 0) {
        return;
      }

      this.aisleActive = index;
    },

    depositCheckedMet() {
      this.depositChecked = !this.depositChecked;
    },

    // 重置逻辑
    resetClick() {
      this.aisleActive = -1;
      this.depositChecked = false;
      this.depositPrice = "";
    },

    // 开始支付逻辑-----------------------------------------
    paymentClick() {
      if (this.depositChecked == false || this.depositPrice == "") {
        this.$toast({
          message: "请先填写资料或勾选协议！",
        });
        return;
      }

      if (this.sourceIndex == 0) {
        if (this.aisleActive == -1) {
          this.$toast({
            message: "请先选择充值通道！",
          });
          return;
        }

        // if (
        //   Number(this.depositPrice) <
        //   Number(this.depositObj.collabrate_recharge)
        // ) {
        //   this.$toast({
        //     message: `缴纳金额最低不能低于${this.depositObj.collabrate_recharge}！`,
        //   });
        //   return;
        // }

        this.hintStop(1);
        // this.listShow = true;
      } else {
        if (this.sourceIndex == 1) {
          // 单独退款
          let num =
            Number(this.depositObj.depositMoney) - Number(this.depositPrice);

          if (Number(num) < Number(this.depositObj.collabrate_recharge)) {
            this.$toast({
              message: `当前是部分退款保证金通道，账户保证金余额不能小于${this.depositObj.collabrate_recharge}元，请重新输入退款金额。`,
            });

            return;
          }
          // 单独退款
          this.refundMet();
        } else {
          this.refundAllMet();
        }
      }
    },

    // 微信支付逻辑
    WeChatMet() {
      let urls = `https://front.xmw1688.com/pay/#/??userId=${this.depositObj.id}&totalFee=${this.depositPrice}&orderNumber=${this.aggregatepaymentOrder}&token=${this.personageObj.accessToken}&differentiate=2`;
      localStorage.setItem("link", urls);
      localStorage.setItem("orderNum", this.aggregatepaymentOrder);
      localStorage.setItem("depositPrice", this.depositPrice);

      this.$router.push({
        path: "/personage-deposit",
      });

      // window.location.href = urls;

      // let _this = this;
      // _this.getOrderstate();

      // let params = {
      //   total_fee: this.depositPrice,
      //   orderNum: this.aggregatepaymentOrder,
      //   platform: "Yinsheng",
      //   way: "app",
      //   channel: "wechat",
      // };
      // postEarnest_pay(params).then((res) => {
      //   if (res.code == 200) {
      //     // let obj = JSON.parse(res.content.bizResponseJson.payData);

      //     // this.onBridgeReady(obj);

      //     window.location.href = res.content.bizResponseJson.payData;

      //     let _this = this;
      //     _this.getOrderstate();
      //   }
      // });
    },

    //--------------唤起支付
    // 调取支付
    // onBridgeReady(item) {
    //   console.log(item)
    //   WeixinJSBridge.invoke(
    //     "getBrandWCPayRequest",
    //     {
    //       appId: item.appId, //公众号名称，由商户传入
    //       timeStamp: item.timeStamp, //时间戳，自1970年以来的秒数
    //       nonceStr: item.nonceStr, //随机串
    //       package: item.package,
    //       signType: item.signType, //微信签名方式：
    //       paySign: item.paySign, //微信签名
    //     },
    //     function (res) {
    //       if (res.err_msg == "get_brand_wcpay_request:ok") {
    //       } else {
    //       } // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
    //     }
    //   );
    // },

    // 循环监听订单是否完成
    getOrderstate(msga) {
      //判断支付是否成功，监听微信支付是否成功
      let _this = this;
      let num = 0;
      _this.timers = setInterval(() => {
        num++;
        let params = {
          orderNum: this.aggregatepaymentOrder, //订单号
        };
        getIsRecharge(params).then((res) => {
          if (res.code == 201) {
            if (res.msg == "未支付") {
              return;
            }
          } else {
            clearInterval(this.timers);
            this.$router.replace({
              path: "/personage-cash-success?price=" + this.depositPrice,
            });
          }
        });
        if (num == 500) {
          (this.ispay = false),
            //别忘了清除定时器
            clearInterval(this.timers);
        }
      }, 1500);
    },
    // ----------------退款逻辑
    refundMet() {
      let params = {
        total_fee: this.depositPrice,
      };

      postDepositMoneyWithraw(params).then((res) => {
        if (res.code == 200) {
          this.$toast({
            message: "退款成功！",
          });

          this.$router.replace("/personage-partner");
        }
      });
    },
    refundAllMet() {
      let params = {};
      postDepositAllMoneyWithraw(params).then((res) => {
        if (res.code == 200) {
          this.$toast({
            message: "全部退款成功！",
          });

          this.$router.replace({
            path: "/personage",
          });
        }
      });
    },

    // 取消蒙版----------------
    isClick() {
      this.showTip = !this.showTip;
    },
    closeMet() {
      this.listShow = false;
    },
    hintStop(index) {
      if (index == 0) {
        this.listShow = false;
      } else {
        clearInterval(this.timers);

        console.log("用户进行支付", this.depositPrice);

        this.alipayMet()

        // if (this.aisleActive == 2) {
        //   this.$router.replace({
        //     path: "/personage-bank?price=" + this.depositPrice,
        //   });

        //   return;
        // }

        // if (this.aisleActive == 3) {
        //   this.$router.replace({
        //     path: "/personage-cash-Internetbank?price=" + this.depositPrice,
        //   });

        //   return;
        // }

        // this.generateOrderMet();
        // this.listShow = false;
      }
    },
    // --------生成订单号
    // generateOrderMet() {
    //   let params = {
    //     type: 3,
    //   };
    //   getOrderNum(params).then((res) => {
    //     this.aggregatepaymentOrder = res.content;

    //     this.alipayMet();
    //     // if (this.aisleActive == 0) {
    //     //   this.WeChatMet();
    //     // } else if (this.aisleActive == 1) {
    //     //   this.alipayMet();
    //     // } else {
    //     // }
    //   });
    // },

    // 支付宝支付逻辑
    alipayMet() {
      let params = {
        total_fee: this.depositPrice,
        // orderNum: this.aggregatepaymentOrder,
        platform: "Yinsheng",
        way: "app",
        channel: "alipay",
      };
      postCollaborateot_pay(params).then((res) => {
        if (res.code == 200) {

          // this.supporyShowTitle = res.content.bizResponseJson.payData;
          // this.supporyShow = true;
          window.location.href = res.content.bizResponseJson.payData;

          // let _this = this;
          // _this.getOrderstate();
        }
      });
    },


    openNewWindow(event) {
      // 使用原生 DOM 事件触发器来避免安全拦截
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = this.supporyShowTitle;
      // 使用 event 参数来确保这不是通过 Vue 事件系统触发的
      link.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        })
      );
    },
  },
};
</script>
  
  <style lang="less" scoped>
.cash {
  padding: 0 18px;
  box-sizing: border-box;
  .first {
    display: flex;
    align-items: center;
    height: 66px;
    border-bottom: 1px solid #eaeaea;

    .first-1 {
      width: 19px;
      height: 19px;
      background: #11d653;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      margin-right: 12px;
    }

    .first-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
    }

    .first-img {
      width: 6.5px;
      margin: 0 10px;
      margin-top: -5px;

      img {
        width: 100%;
      }
    }
  }

  .second {
    margin-top: 20px;
    text-align: left;

    .second-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #757575;
      margin-bottom: 19px;
    }

    .second-error {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      margin: 10px 0 20px;
      color: #999999;
    }

    .second-input {
      margin-bottom: 19px;
      /deep/ .el-input {
        border-bottom: 1px solid #eaeaea;
      }

      /deep/ .el-input__inner {
        border: 0;
      }

      /deep/ .el-input__inner {
        padding-left: 10px;
      }
    }

    .second-aisle {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .aisle-list {
        width: calc((100% / 3) - 8px);
        height: 39px;
        justify-content: center;
        border: 1px solid #e0e0e0;
        border-radius: 7px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 8px;

        img {
          margin-right: 5px;
        }

        .list-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #757575;
        }
      }

      .aisle-list:nth-child(3n + 3) {
        margin-right: 0;
      }

      .aisle-list-active {
        border: 1px solid #11d653;
      }
    }
  }

  .button {
    margin-top: 62px;

    .button-top {
      width: 340px;
      height: 47px;
      background: #11d653;
      border-radius: 23.5px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    .button-text {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .text-checkbox {
        width: 16px;
        height: 16px;
        border: 1px solid;
        border-radius: 50%;
        margin-right: 10px;
      }

      .text-checkboxs {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }

      .text-checka {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
      }
    }
  }
}

.hint {
  width: 85vw;
  border-radius: 10px;

  .hint-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .header {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #222222;
    }

    .close {
      position: absolute;
      right: 20px;
      width: 20px;
      height: 20px;
    }
  }

  .hint-center {
    padding: 0 20px 20px;
    box-sizing: border-box;
    text-align: left;
    .center {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #222222;
      line-height: 30px;
    }

    .display {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .display-list {
        width: 125px;
        height: 43px;
        border-radius: 21px;
        border: 1px solid #999999;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #222222;
      }

      .display-list:last-child {
        margin-left: 15px;
        color: #fff;
        background: #29d647;
      }
    }
  }
}
</style>