import { render, staticRenderFns } from "./fastPayment.vue?vue&type=template&id=1079c017&scoped=true"
import script from "./fastPayment.vue?vue&type=script&lang=js"
export * from "./fastPayment.vue?vue&type=script&lang=js"
import style0 from "./fastPayment.vue?vue&type=style&index=0&id=1079c017&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1079c017",
  null
  
)

export default component.exports